import "styles/pages/404.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"

const NotFoundPage = () => {
  const title = "404"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="page-404">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row align-items-center flex-column-reverse flex-sm-row">
            <div className="col-lg-4 offset-lg-1 col-sm-6">
              <img
                className="img-fluid page-404__image"
                src={require("assets/images/page-404-main-image.svg").default}
                alt=""
              />
            </div>
            <div className="col-lg-5 offset-lg-1 col-sm-6">
              <PageSubHeader title="Wygląda na to, że znalazłeś się w złym miejscu" />
              <p>
                Ustalmy, że Cię tutaj nie było. Nic nie widziałeś, nic nie
                słyszałeś, nic nie wiesz i nikomu o niczym nie wspomnisz. Teraz
                możesz wrócić na właściwą ścieżkę.
              </p>
              <Button type="link" to="/">
                Strona główna
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
